import React from "react"
import { graphql } from 'gatsby'

import Layout from '../layout'

import BackgroundContainer from '../components/image/background-container'
import TitleContainer from '../components/common/title-container'
import Title from '../components/common/title'

export default (props) => (
    <Layout title="Page not found">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
              <Title>Page not found</Title>
            </TitleContainer>
        </BackgroundContainer>
    </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "saas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
